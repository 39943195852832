/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import './theme/fonts.scss';
@import './theme/custom.scss';

@import 'swiper/css';

.myalert-select {
	--min-width: 80% !important;
}

.month-header {
	.item-inner {
		background: #cbdfec;
	}

	.label-md,
	.label-ios {
		margin-top: 0;
		margin-bottom: 0;
	}

	p {
		color: #00516f;
		font-weight: bold;
		font-size: 18px;
		line-height: 21px;
		text-transform: uppercase;
		margin: 0;
	}
}

[data-allowed='false'] {
	color: var(--ion-color-medium);
	pointer-events: none;
}

[data-allowed='true'] {
	color: var(--ion-color-medium);
	pointer-events: all;
}

.monthview-datetable {
	border: none !important;

	th,
	td {
		border: none !important;
	}

	td {
		line-height: 24px !important;
		height: 30px;
		background-color: transparent !important;

		&.text-muted {
			display: none;
		}
		&.monthview-secondary-with-event,
		&.monthview-primary-with-event {
			.wrapper .wrapper-value {
				&::before {
					content: ' ';
					width: 4px;
					height: 4px;
					background: transparent;
					display: none;
					position: absolute;
					left: calc(50% - 2px);
					border-radius: 100%;
					top: -1px;
					background: black;
				}

				color: var(--ion-color-primary);
			}
		}

		&:not(.text-muted) {
			color: var(--ion-color-primary);

			&.monthview-current {
				.wrapper .wrapper-value {
					color: var(--ion-color-primary);
				}
			}
			&.monthview-selected {
				.wrapper {
					background: #197cc0;
					border-radius: 51% !important;
					width: 28px;
					margin: auto;
					height: 28px;
					line-height: 28px;

					.wrapper-value {
						color: white;
						font-weight: bold !important;
					}
				}
			}
		}
	}
}
