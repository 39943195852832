ion-title {
	font-weight: lighter;
	--font-weight: lighter;
	font-size: 24px;
}

.text-normal {
	font-size: 14px;
	font-weight: normal;
}

.text-title {
	font-size: 22px;
	font-weight: bold;
}

.text-subtitle {
	font-weight: bold;
	font-size: 14px;
}

.header-md::after {
	background-image: none;
}

.header-md {
	box-shadow: none;
}

.button-solid {
	box-shadow: none;
	--box-shadow: none;
	--border-radius: 2;
	--letter-spacing: 0;
	letter-spacing: 0;
	text-transform: none;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 29px;
}

ion-content {
	border-top-left-radius: 20px;
	border-top: 15px solid white;
	border-top-right-radius: 20px;
}

ion-footer {
	background: #000000;
	color: white;
	height: 90px;
	border-radius: 20px 20px 0px 0px;
	margin-top: -16px;

	.button-solid {
		font-weight: 500;
		font-size: 20px;
		line-height: 40px;
		margin: 0;
		margin-top: auto;
	}
}

ion-currency-mask {
	ion-item {
		text-align: center !important;
	}
}

.ion-page {
	background: var(--ion-color-grey);
}

//Form
.form {
	padding-left: 16px;
	padding-right: 16px;
	text-align: center;

	ion-label {
		font-weight: bold;
		font-size: 16px;
		line-height: 19px;
		margin-bottom: 8px;
		display: block;
	}

	ion-item {
		background: #ffffff;
		border: 1px solid #e8e8e8;
		box-sizing: border-box;
		border-radius: 2px;
		margin-bottom: 16px;
		--border-width: 0;
		--highlight-height: 1px;
		--inner-border-width: 0;
	}

	ion-button {
		border-radius: 2px;
	}
}

//List
.list-aauav {
	padding-top: 0;
	ion-item {
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		border-style: solid;
		border: 0.5px solid #dbdbdb;
		border-width: 1px 1px 0px 1px;
		--border-width: 0;
		--inner-border-width: 0;

		color: #1b1b1b;
		font-size: 16px;
		font-weight: 500;
		padding-left: 5px;
	}
}

.alert-radio-label.sc-ion-alert-md {
	white-space: normal;
}

form {
	max-width: 500px;
	margin: auto;
}

.sold-out {
	background: red;
	font-size: 12px;
	padding: 3px;
	border-radius: 3px;
	color: #fff;
}
ion-footer::before,
.footer-md:before {
	background-image: none;
}

.swiper-pagination-bullet {
	background: var(--ion-color-primary) !important;
}

[hidden] {
	visibility: hidden !important;
}
